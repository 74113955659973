<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tree: [],
      transations: [],
      current: [],
      mainChart: [],
      data: {},
    };
  },
  methods: {
    search() {
      this.tree = [];
      this.http.post("transactions/statements", this.data).then((res) => {
        this.tree.push(res.data);
        console.log("////*****//", this.tree);
        if (this.data.main_id == "*") {
          this.getTree();
        }

        this.transations = this.tree;
        for (let i = 0; i < this.transations.length; i++) {
          for (
            let x = 0;
            x < this.transations[i]?.level_one_chart_of_accounts.length;
            x++
          ) {
            for (
              let z = 0;
              z <
              this.transations[i].level_one_chart_of_accounts[x]
                .level_two_chart_of_accounts.length;
              z++
            ) {
              for (
                let c = 0;
                c <
                this.transations[i].level_one_chart_of_accounts[x]
                  .level_two_chart_of_accounts[z].level_three_chart_of_accounts
                  .length;
                c++
              ) {
                this.transations[i].level_one_chart_of_accounts[
                  x
                ].level_two_chart_of_accounts[z].level_three_chart_of_accounts[
                  c
                ]["number"] = 0;
                for (
                  let n = 0;
                  n <
                  this.transations[i].level_one_chart_of_accounts[x]
                    .level_two_chart_of_accounts[z]
                    .level_three_chart_of_accounts[c].transactions.length;
                  n++
                ) {
                  this.transations[i].level_one_chart_of_accounts[
                    x
                  ].level_two_chart_of_accounts[
                    z
                  ].level_three_chart_of_accounts[c]["number"] += parseFloat(
                    this.transations[i].level_one_chart_of_accounts[x]
                      .level_two_chart_of_accounts[z]
                      .level_three_chart_of_accounts[c].transactions[n].value
                  );
                }
              }
            }
          }
        }
      });
    },
    MainChart() {
      this.http.get("main-chart-of-accounts-types").then((res) => {
        this.mainChart = res.data;
      });
    },
    getTree() {
      console.log("transactions/statements");
      this.http
        .post("transactions/statements", { main_id: "*" })
        .then((res) => {
          this.tree = res.data;

          this.transations = this.tree;
          for (let i = 0; i < this.transations.length; i++) {
            for (
              let x = 0;
              x < this.transations[i].level_one_chart_of_accounts.length;
              x++
            ) {
              for (
                let z = 0;
                z <
                this.transations[i].level_one_chart_of_accounts[x]
                  .level_two_chart_of_accounts.length;
                z++
              ) {
                for (
                  let c = 0;
                  c <
                  this.transations[i].level_one_chart_of_accounts[x]
                    .level_two_chart_of_accounts[z]
                    .level_three_chart_of_accounts.length;
                  c++
                ) {
                  this.transations[i].level_one_chart_of_accounts[
                    x
                  ].level_two_chart_of_accounts[
                    z
                  ].level_three_chart_of_accounts[c]["number"] = 0;
                  for (
                    let n = 0;
                    n <
                    this.transations[i].level_one_chart_of_accounts[x]
                      .level_two_chart_of_accounts[z]
                      .level_three_chart_of_accounts[c].transactions.length;
                    n++
                  ) {
                    this.transations[i].level_one_chart_of_accounts[
                      x
                    ].level_two_chart_of_accounts[
                      z
                    ].level_three_chart_of_accounts[c]["number"] += parseFloat(
                      this.transations[i].level_one_chart_of_accounts[x]
                        .level_two_chart_of_accounts[z]
                        .level_three_chart_of_accounts[c].transactions[n].value
                    );
                  }
                }
              }
            }
          }

          console.log("**********", this.transations);
        });
    },
  },
  created() {
    this.getTree();
    this.MainChart();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.accounting.subItems.financial-statments')"
    />
    <div class="row">
      <div class="col-3">
        <select
          v-model="data.main_id"
          class="form-select"
          aria-label="Default select example"
        >
          <option value="*">All</option>
          <option v-for="main in mainChart" :key="main" :value="main.id">
            {{ main.name }}
          </option>
        </select>
      </div>
      <div class="col-7">
        <button type="button" class="btn btn-primary" @click="search()">
          {{ $t("popups.search") }}
        </button>
      </div>
    </div>
    <!-- <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
<th scope="col"> <span class="me-5">Current</span></th>
    
          
        </tr>
      </thead>
      <tbody>
       <tr  v-for="i in tree" :key="i" >
<td>
  {{ i.name }}

<tr v-for="x in i.level_one_chart_of_accounts" :key="x">
   {{ x.name }}<br>
  <span v-for="y in x.level_two_chart_of_accounts" :key="y">
    {{ y.name }}<br>
    <span class="me-3" v-for="z in y.level_three_chart_of_accounts" :key="z">{{z.name}} <br></span>
  </span>
 
</tr>







</td>

       </tr>
       
      </tbody>
    </table> 
  -->

    <div class="row mt-5">
      <div class="col-6">
        <ul id="myUL" v-for="i in tree" :key="i" style="font-size: 15px">
          <li>
            <span class="caret" style="font-weight: bold">{{ i.name }}</span>
            <ul
              v-for="x in i.level_one_chart_of_accounts"
              :key="x"
              class="nested"
            >
              <li>
                <span class="caret">{{ x.name }}</span>
                <ul
                  class="nested"
                  v-for="y in x.level_two_chart_of_accounts"
                  :key="y"
                >
                  <li>
                    <span class="caret"
                      >{{ y.name }}

                      <li>
                        <div
                          class="row"
                          v-for="z in y.level_three_chart_of_accounts"
                          :key="z"
                        >
                          <div class="col-10">
                            <span class="caret ms-5">{{ z.name }}<br /></span>
                          </div>
                          <div class="col-2">
                            <div class="row">
                              <div
                                class="col-6"
                                style="position: relative; left: 110px"
                              >
                                <span>{{
                                  z?.opening_balances[0]?.amount
                                }}</span>
                              </div>
                              <div
                                class="col-6"
                                style="position: relative; left: 150px"
                              >
                                <span class="caret ms-5"
                                  >{{ z.number }}<br
                                /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-3">
            <h5
              style="
                position: relative;

                right: 40px;
              "
            >
              Open
            </h5>
          </div>
          <div class="col-3">
            <h5
              style="
                position: relative;

                right: 100px;
              "
            >
              Current
            </h5>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

ul,
#myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari */
  transform: rotate(90deg);
}

.nested {
  display: block;
}

.active {
  display: block;
}
</style>
